import React, { useEffect, useState, useRef } from 'react';
import './style.css';

const companyName = 'BlackPixel';
const companyNameContact = '+91-673273287';
const companyAddress1 = 'Maumbai, ';
const companyAddress2 = 'Mumbai, Maharashtra, ';
const companyAddress3 = 'India';
const companyEmail = 'blackrock@gmail.com';

const webData = {
	aboutUs: {
		titleContent: `Welcome to ${companyName}, a leading provider of online solutions for web and mobile development.`,
		description: `At ${companyName}, we are passionate about leveraging cutting-edge technologies to deliver innovative and tailored solutions that meet the unique needs of our clients.
                Our mission at ${companyName} is to empower businesses and individuals with digital solutions that drive growth, enhance productivity, and foster success in the ever-evolving digital landscape.`,
		pointers: [
			{
				title: 'Expertise',
				body:
					'With a team of highly skilled developers, designers, and strategists, we bring expertise in a wide range of technologies and platforms.',
				img: '',
			},
			{
				title: 'Custom Solutions',
				body: `We understand that every project is unique. That's why we take a customized approach to every project, ensuring that our solutions align with your specific goals and requirements.`,
				img: '',
			},
			{
				title: 'Innovation',
				body: `We stay ahead of the curve by embracing the latest trends and technologies in web and mobile development. We continuously innovate to deliver solutions that drive results.`,
				img: '',
			},
		],
		paragraph1: {
			title: 'Leading Technologies',
			content: [
				"As a team, we're dedicated to harnessing the power of cutting-edge web technologies, including NoSQL databases like MongoDB and Firebase. Integrating NoSQL solutions into our projects allows us to build scalable and flexible web applications that can adapt to evolving business requirements. By combining NoSQL databases with frameworks like MERN (MongoDB, Express.js, React.js, Node.js) and MEAN (MongoDB, Express.js, Angular, Node.js), we ensure our ability to deliver high-performance, feature-rich web solutions that exceed client expectations. This approach enables us to stay ahead in the fast-paced world of web development, driving innovation and success for both our clients and our team.",
			],
		},
		paragraph2: {
			title: 'Collaborative Approach',
			content: [
				'We believe in building strong relationships with our clients through open communication, collaboration, and transparency. Your success is our success.',
				'At our core, we believe in nurturing robust client relationships rooted in open dialogue, collaborative efforts, and unwavering transparency. Your triumph is our triumph; thus, we align our aspirations with yours, ensuring mutual success. Through active engagement and shared objectives, we cultivate an environment where communication flourishes.',
				'Together, we navigate challenges, exchange ideas, and celebrate achievements. With steadfast dedication and a commitment to excellence, we deliver solutions that not only meet but exceed expectations, solidifying our partnership and propelling us towards continued success.',
			],
		},
	},
	services: {
		title: 'Our Services',
		body:
			'At [Your Company Name], we offer a comprehensive range of services to meet your digital needs.',
		pointers: {
			'Web Development': {
				title: 'Web Development',
				body:
					'Crafting dynamic and engaging websites and web applications using modern technologies.',
				icon: 'ion-ios-world-outline',
			},
			'Responsive Websites': {
				title: 'Responsive Websites',
				body:
					'Ensuring seamless user experiences across all devices and screen sizes.',
				icon: 'ion-code-working',
			},
			MERN: {
				title: 'MERN Stack Development',
				body:
					'Improving search engine visibility and driving organic traffic to your website.',
				icon: 'ion-ios-heart',
			},
			'Frontend Development': {
				title: 'Frontend Development',
				body:
					'Designing intuitive and user-friendly interfaces that captivate audiences.',
				icon: 'ion-ios-bookmarks-outline',
			},
			'Backend Development': {
				title: 'Backend Development',
				body:
					'Building robust backend systems to support your web and mobile applications.',
				icon: 'ion-ios-cog-outline',
			},
			'Mobile App Development': {
				title: 'Apps',
				body:
					'Developing mobile applications that deliver exceptional performance and user experiences.',
				icon: 'ion-ios-phone-portrait',
			},
			'Cloud Hosting': {
				title: 'Cloud Hosting',
				body:
					'Deploying and managing scalable and reliable applications on the AWS cloud platform.',
				icon: 'ion-ios-cloud-outline',
			},
			SEO: {
				title: 'SEO',
				body:
					'Improving search engine visibility and driving organic traffic to your website.',
				icon: 'ion-ios-settings',
			},
			'Database Management': {
				title: 'Database Management',
				body:
					'Improving search engine visibility and driving organic traffic to your website.',
				icon: 'ion-ios-timer',
			},
			'Maintenance & Support': {
				title: 'Maintenance & Support',
				body:
					'Improving search engine visibility and driving organic traffic to your website.',
				icon: 'ion-ios-search',
			},
		},
	},
	whyChoose: {
		description: 'There are multiple reasons to choose us.',
		sections: [
			{
				img: 'fa fa-diamond',
				title: 'Experienced Team',
				description:
					'Our team consists of highly experienced professionals dedicated to delivering quality results.',
			},
			{
				img: 'fa fa-users',
				title: 'Customer Satisfaction',
				description:
					"We prioritize customer satisfaction and strive to meet and exceed our clients' expectations.",
			},
			{
				img: 'fa fa-lightbulb-o',
				title: 'Innovative Solutions',
				description:
					'We offer innovative and cutting-edge solutions tailored to meet your specific needs and requirements.',
			},
		],
	},
	team: {
		description:
			'Our team is comprised of  highly motivated, skilled and innovative members.',
		members: [
			{
				name: 'Omlar Bodul',
				title: 'CEO',
				img: './img/team-1.jpg',
				linkedin: '',
				info: '',
			},
			{
				name: 'Vishal Gaddam',
				title: 'Tech Lead',
				linkedin: 'https://www.linkedin.com/in/vishal-gaddam-7a20b1178/',
				img: './img/team-2.jpg',
				info: '',
			},
			{
				name: 'Shubham Verma',
				title: 'Sr. Font End Developer',
				img: './img/team-3.jpg',
				linkedin: '',
				info: '',
			},
			{
				name: 'Mahdi Ahmad',
				title: 'Sr. UI/UX Developer',
				img: './img/team-4.jpg',
				linkedin: '',
				info: '',
			},
			{
				name: 'Abhijeet Holkar',
				img: './img/team-5.jpg',
				title: 'SEO Engineer',
				linkedin: 'https://www.linkedin.com/in/abhijeet-holkar-444a6719b/',
				info: '',
			},
		],
	},
	clients: {
		description: 'Here is list of our clients',
		clients: [
			{
				img: 'https://emediskill.com/static/media/headerLogo.718afd2596efb4aeb38f0a6660a67f71.svg',
				link: 'https://emediskill.com/',
			},
			{
				img: 'https://cargo411.com/public/assets/img/logo.png',
				link: 'https://cargo411.com/',
			},
			{
				img: 'https://play-lh.googleusercontent.com/ozu6ue625GLWIYqq1hD1ggkBm7PsAIIhPSVJKjx0qseJ7Hgl9_DlDSbyV_uMHnLgqg=w240-h480-rw',
				link: 'https://play.google.com/store/apps/details?id=com.profmed.services',
			},
			{
				img: 'https://raw.githubusercontent.com/vishalgaddam873/Portfolio/main/assets/images/pixelmath-logo.png',
				link: '',
			},
		],
	},
	footer: {
		description: 'This is the description to be shown in the footer',
	},
};

function HomePage() {
	return (
		<div>
			<header id='header' className='fixed-top'>
				<div className='container'>
					<div className='logo float-left d-flex align-items-center'>
						<a href='#intro' className='scrollto'>
							<img src='img/logo.png' alt='' className='img-fluid' />
						</a>

						<h1 className='text-light' style={{ marginRight: '10px' }}>
							<a href='#header'>
								<span>{companyName}</span>
							</a>
						</h1>
					</div>

					<nav className='main-nav float-right d-none d-lg-block'>
						<ul>
							<li className='active'>
								<a href='#intro'>Home</a>
							</li>
							<li>
								<a href='#about'>About Us</a>
							</li>
							<li>
								<a href='#services'>Services</a>
							</li>
							{/* <li><a href="#portfolio">Portfolio</a></li> */}
							<li>
								<a href='#team'>Team</a>
							</li>
							<li>
								<a href='#contact'>Contact Us</a>
							</li>
						</ul>
					</nav>
				</div>
			</header>

			<section id='intro' className='clearfix'>
				<div className='container'>
					<div className='intro-img'>
						<img src='img/intro-img.svg' alt='' className='img-fluid' />
					</div>

					<div className='intro-info'>
						<h2>
							We provide
							<br />
							<span>solutions</span>
							<br />
							for your business!
						</h2>
						<div>
							<a href='#about' className='btn-get-started scrollto'>
								Get Started
							</a>
							<a href='#services' className='btn-services scrollto'>
								Our Services
							</a>
						</div>
					</div>
				</div>
			</section>

			<section id='about'>
				<div className='container'>
					<header className='section-header'>
						<h3>About Us</h3>
						<p>{webData.aboutUs.titleContent}</p>
					</header>

					<div className='row about-container'>
						<div className='col-lg-6 content order-lg-1 order-2'>
							<p>{webData.aboutUs.description}</p>

							{webData.aboutUs.pointers.map((pointer, index) => {
								return (
									<div
										className='icon-box wow fadeInUp'
										data-wow-delay={index * 0.5 + 's'}>
										<div className='icon'>
											<i className='fa fa-shopping-bag'></i>
										</div>
										<h4 className='title'>
											<a href=''>{pointer.title}</a>
										</h4>
										<p className='description'>{pointer.body}</p>
									</div>
								);
							})}
						</div>

						<div className='col-lg-6 background order-lg-2 order-1 wow fadeInUp'>
							<img src='img/about-img.svg' className='img-fluid' alt='' />
						</div>
					</div>

					<div className='row about-extra'>
						<div className='col-lg-6 wow fadeInUp'>
							<img src='img/about-extra-1.svg' className='img-fluid' alt='' />
						</div>
						<div className='col-lg-6 wow fadeInUp pt-5 pt-lg-0'>
							<h4>{webData.aboutUs.paragraph1.title}</h4>
							{webData.aboutUs.paragraph1.content.map((content, index) => {
								return <p key={index}>{content}</p>;
							})}
						</div>
					</div>

					<div className='row about-extra'>
						<div className='col-lg-6 wow fadeInUp order-1 order-lg-2'>
							<img src='img/about-extra-2.svg' className='img-fluid' alt='' />
						</div>

						<div className='col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1'>
							<h4>{webData.aboutUs.paragraph2.title}</h4>
							{webData.aboutUs.paragraph2.content.map((content, index) => {
								return <p key={index}>{content}</p>;
							})}
						</div>
					</div>
				</div>
			</section>

			<section id='services' className='section-bg'>
				<div className='container'>
					<header className='section-header'>
						<h3>Services</h3>
						<p>{webData.services.title}</p>
					</header>

					<div className='row'>
						{Object.keys(webData.services.pointers).map((key, index) => {
							return (
								<div
									key={index}
									className={'col-md-4 col-lg-4 wow bounceInUp mb-2'}
									data-wow-duration='1.4s'>
									<div className='box' style={{ height: '80%' }}>
										<div className='icon'>
											<i
												className={webData.services.pointers[key].icon}
												style={{ color: '#ff689b' }}></i>
										</div>
										<h4 className='title'>
											<a href=''>{key}</a>
										</h4>
										<p className='description'>{webData.services.pointers[key].body}</p>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</section>

			<section id='why-us' className='wow fadeIn'>
				<div className='container'>
					<header className='section-header'>
						<h3>Why choose us?</h3>
						<p>{webData.whyChoose.description}</p>
					</header>

					<div className='row row-eq-height justify-content-center'>
						{webData.whyChoose.sections.map((section, index) => {
							return (
								<div className='col-lg-4 mb-4' key={index}>
									<div className='card wow bounceInUp'>
										<i className={section.img}></i>
										<div className='card-body'>
											<h5 className='card-title'>{section.title}</h5>
											<p className='card-text'>{section.description}</p>
											{/* <a href="#" className="readmore">Read more </a> */}
										</div>
									</div>
								</div>
							);
						})}
					</div>

					<div className='row counters'>
						<div className='col-lg-4 col-6 text-center'>
							<span data-toggle='counter-up'>9872+</span>
							<p>Hours Of Code</p>
						</div>

						<div className='col-lg-4 col-6 text-center'>
							<span data-toggle='counter-up'>1,364</span>
							<p>Hours Of Support</p>
						</div>

						<div className='col-lg-4 col-6 text-center'>
							<span data-toggle='counter-up'>8+</span>
							<p>Hard Workers</p>
						</div>
					</div>
				</div>
			</section>

			{/* <section id="portfolio" className="clearfix">
          <div className="container">
    
            <header className="section-header">
              <h3 className="section-title">Our Portfolio</h3>
            </header>
    
            <div className="row">
              <div className="col-lg-12">
                <ul id="portfolio-flters">
                  <li data-filter="*" className="filter-active">All</li>
                  <li data-filter=".filter-app">App</li>
                  <li data-filter=".filter-card">Card</li>
                  <li data-filter=".filter-web">Web</li>
                </ul>
              </div>
            </div>
    
            <div className="row portfolio-container">
    
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="img/portfolio/app1.jpg" className="img-fluid" alt=""/>
                  <div className="portfolio-info">
                    <h4><a href="#">App 1</a></h4>
                    <p>App</p>
                    <div>
                      <a href="img/portfolio/app1.jpg" data-lightbox="portfolio" data-title="App 1" className="link-preview" title="Preview"><i className="ion ion-eye"></i></a>
                      <a href="#" className="link-details" title="More Details"><i className="ion ion-android-open"></i></a>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="col-lg-4 col-md-6 portfolio-item filter-web" data-wow-delay="0.1s">
                <div className="portfolio-wrap">
                  <img src="img/portfolio/web3.jpg" className="img-fluid" alt=""/>
                  <div className="portfolio-info">
                    <h4><a href="#">Web 3</a></h4>
                    <p>Web</p>
                    <div>
                      <a href="img/portfolio/web3.jpg" className="link-preview" data-lightbox="portfolio" data-title="Web 3" title="Preview"><i className="ion ion-eye"></i></a>
                      <a href="#" className="link-details" title="More Details"><i className="ion ion-android-open"></i></a>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="col-lg-4 col-md-6 portfolio-item filter-app" data-wow-delay="0.2s">
                <div className="portfolio-wrap">
                  <img src="img/portfolio/app2.jpg" className="img-fluid" alt=""/>
                  <div className="portfolio-info">
                    <h4><a href="#">App 2</a></h4>
                    <p>App</p>
                    <div>
                      <a href="img/portfolio/app2.jpg" className="link-preview" data-lightbox="portfolio" data-title="App 2" title="Preview"><i className="ion ion-eye"></i></a>
                      <a href="#" className="link-details" title="More Details"><i className="ion ion-android-open"></i></a>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="img/portfolio/card2.jpg" className="img-fluid" alt=""/>
                  <div className="portfolio-info">
                    <h4><a href="#">Card 2</a></h4>
                    <p>Card</p>
                    <div>
                      <a href="img/portfolio/card2.jpg" className="link-preview" data-lightbox="portfolio" data-title="Card 2" title="Preview"><i className="ion ion-eye"></i></a>
                      <a href="#" className="link-details" title="More Details"><i className="ion ion-android-open"></i></a>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="col-lg-4 col-md-6 portfolio-item filter-web" data-wow-delay="0.1s">
                <div className="portfolio-wrap">
                  <img src="img/portfolio/web2.jpg" className="img-fluid" alt=""/>
                  <div className="portfolio-info">
                    <h4><a href="#">Web 2</a></h4>
                    <p>Web</p>
                    <div>
                      <a href="img/portfolio/web2.jpg" className="link-preview" data-lightbox="portfolio" data-title="Web 2" title="Preview"><i className="ion ion-eye"></i></a>
                      <a href="#" className="link-details" title="More Details"><i className="ion ion-android-open"></i></a>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="col-lg-4 col-md-6 portfolio-item filter-app" data-wow-delay="0.2s">
                <div className="portfolio-wrap">
                  <img src="img/portfolio/app3.jpg" className="img-fluid" alt=""/>
                  <div className="portfolio-info">
                    <h4><a href="#">App 3</a></h4>
                    <p>App</p>
                    <div>
                      <a href="img/portfolio/app3.jpg" className="link-preview" data-lightbox="portfolio" data-title="App 3" title="Preview"><i className="ion ion-eye"></i></a>
                      <a href="#" className="link-details" title="More Details"><i className="ion ion-android-open"></i></a>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="img/portfolio/card1.jpg" className="img-fluid" alt=""/>
                  <div className="portfolio-info">
                    <h4><a href="#">Card 1</a></h4>
                    <p>Card</p>
                    <div>
                      <a href="img/portfolio/card1.jpg" className="link-preview" data-lightbox="portfolio" data-title="Card 1" title="Preview"><i className="ion ion-eye"></i></a>
                      <a href="#" className="link-details" title="More Details"><i className="ion ion-android-open"></i></a>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="col-lg-4 col-md-6 portfolio-item filter-card" data-wow-delay="0.1s">
                <div className="portfolio-wrap">
                  <img src="img/portfolio/card3.jpg" className="img-fluid" alt=""/>
                  <div className="portfolio-info">
                    <h4><a href="#">Card 3</a></h4>
                    <p>Card</p>
                    <div>
                      <a href="img/portfolio/card3.jpg" className="link-preview" data-lightbox="portfolio" data-title="Card 3" title="Preview"><i className="ion ion-eye"></i></a>
                      <a href="#" className="link-details" title="More Details"><i className="ion ion-android-open"></i></a>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="col-lg-4 col-md-6 portfolio-item filter-web" data-wow-delay="0.2s">
                <div className="portfolio-wrap">
                  <img src="img/portfolio/web1.jpg" className="img-fluid" alt=""/>
                  <div className="portfolio-info">
                    <h4><a href="#">Web 1</a></h4>
                    <p>Web</p>
                    <div>
                      <a href="img/portfolio/web1.jpg" className="link-preview" data-lightbox="portfolio" data-title="Web 1" title="Preview"><i className="ion ion-eye"></i></a>
                      <a href="#" className="link-details" title="More Details"><i className="ion ion-android-open"></i></a>
                    </div>
                  </div>
                </div>
              </div>
    
            </div>
    
          </div>
        </section> */}

			{/* <section id="testimonials" className="section-bg">
          <div className="container">
    
            <header className="section-header">
              <h3>Testimonials</h3>
            </header>
    
            <div className="row justify-content-center">
              <div className="col-lg-8">
    
                <div className="owl-carousel testimonials-carousel wow fadeInUp">
        
                  <div className="testimonial-item">
                    <img src="img/testimonial-1.jpg" className="testimonial-img" alt=""/>
                    <h3>Saul Goodman</h3>
                    <h4>Ceo &amp; Founder</h4>
                    <p>
                      Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                    </p>
                  </div>
        
                  <div className="testimonial-item">
                    <img src="img/testimonial-2.jpg" className="testimonial-img" alt=""/>
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <p>
                      Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                    </p>
                  </div>
        
                  <div className="testimonial-item">
                    <img src="img/testimonial-3.jpg" className="testimonial-img" alt=""/>
                    <h3>Jena Karlis</h3>
                    <h4>Store Owner</h4>
                    <p>
                      Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                    </p>
                  </div>
        
                  <div className="testimonial-item">
                    <img src="img/testimonial-4.jpg" className="testimonial-img" alt=""/>
                    <h3>Matt Brandon</h3>
                    <h4>Freelancer</h4>
                    <p>
                      Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                    </p>
                  </div>
        
                  <div className="testimonial-item">
                    <img src="img/testimonial-5.jpg" className="testimonial-img" alt=""/>
                    <h3>John Larson</h3>
                    <h4>Entrepreneur</h4>
                    <p>
                      Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                    </p>
                  </div>
    
                </div>
    
              </div>
            </div>
    
    
          </div>
        </section>
         */}
			<section id='team'>
				<div className='container'>
					<div className='section-header'>
						<h3>Team</h3>
						<p>{webData.team.description}</p>
					</div>

					<div className='row justify-content-center'>
						{webData.team.members.map((member, index) => {
							return (
								<div className='col-lg-3 col-md-6 wow fadeInUp '>
									<div className='member'>
										<img src={member.img} className='img-fluid' alt='' />
										<div className='member-info'>
											<div className='member-info-content'>
												<h4>{member.name}</h4>
												<span>{member.title}</span>
												<div className='social'>
													<a href={member.linkedin}>
														<i className='fa fa-linkedin'></i>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</section>

			<section id='clients' className='section-bg'>
				<div className='container'>
					<div className='section-header'>
						<h3>Our Clients</h3>
						<p>{webData.clients.description}</p>
					</div>

					<div className='row clients-wrap clearfix wow fadeInUp border-0 justify-content-center'>
						{webData.clients.clients.map((client, index) => {
							return (
								<div key={index} className='col-lg-4 col-md-4 col-xs-6 mt-3'>
									<div className='client-logo' style={{ borderRadius: '10px' }}>
										<a href={client.link}>
											<img src={client.img} className='img-fluid' alt='' />
										</a>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</section>

			{/* <section id="contact">
          <div className="container-fluid">
    
            <div className="section-header">
              <h3>Contact Us</h3>
            </div>
    
            <div className="row wow fadeInUp">
    
              <div className="col-lg-6">
                <div className="map mb-4 mb-lg-0">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameBorder="0" style={{border:0, width: '100%', height: '312px'}} allowFullScreen></iframe>
                </div>
              </div>
    
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-md-5 info">
                    <i className="ion-ios-location-outline"></i>
                    <p>A108 Adam Street, NY 535022</p>
                  </div>
                  <div className="col-md-4 info">
                    <i className="ion-ios-email-outline"></i>
                    <p>info@example.com</p>
                  </div>
                  <div className="col-md-3 info">
                    <i className="ion-ios-telephone-outline"></i>
                    <p>+1 5589 55488 55</p>
                  </div>
                </div>
    
                <div className="form">
                  <div id="sendmessage">Your message has been sent. Thank you!</div>
                  <div id="errormessage"></div>
                  <form action="" method="post" role="form" className="contactForm">
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                        <div className="validation"></div>
                      </div>
                      <div className="form-group col-lg-6">
                        <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                        <div className="validation"></div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                      <div className="validation"></div>
                    </div>
                    <div className="form-group">
                      <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                      <div className="validation"></div>
                    </div>
                    <div className="text-center"><button type="submit" title="Send Message">Send Message</button></div>
                  </form>
                </div>
              </div>
    
            </div>
    
          </div>
        </section> */}

			<footer id='footer'>
				<div className='footer-top' id='contact'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-6 col-md-6 footer-info'>
								<h3>{companyName}</h3>
								<p>{webData.footer.description}</p>
							</div>

							<div className='col-lg-3 col-md-6 footer-links'>
								<h4>Useful Links</h4>
								<ul>
									<li>
										<a href='#'>Home</a>
									</li>
									<li>
										<a href='#'>About us</a>
									</li>
									<li>
										<a href='#'>Services</a>
									</li>
									<li>
										<a href='#'>Terms of service</a>
									</li>
									<li>
										<a href='#'>Privacy policy</a>
									</li>
								</ul>
							</div>

							<div className='col-lg-3 col-md-6 footer-contact'>
								<h4>Contact Us</h4>
								<p>
									{companyAddress1}
									<br />
									{companyAddress2}
									<br />
									{companyAddress3}
									<br />
									<strong>Phone:</strong> {companyNameContact}
									<br />
									<strong>Email:</strong> {companyEmail}
									<br />
								</p>
								{/*     
                <div className="social-links">
                  <a href="#" className="twitter"><i className="fa fa-twitter"></i></a>
                  <a href="#" className="facebook"><i className="fa fa-facebook"></i></a>
                  <a href="#" className="instagram"><i className="fa fa-instagram"></i></a>
                  <a href="#" className="google-plus"><i className="fa fa-google-plus"></i></a>
                  <a href="#" className="linkedin"><i className="fa fa-linkedin"></i></a>
                </div> */}
							</div>
						</div>
					</div>
				</div>

				<div className='container'>
					<div className='copyright'>
						&copy; Copyright <strong>{companyName}</strong>. All Rights Reserved
					</div>
				</div>
			</footer>

			<a href='#' className='back-to-top'>
				<i className='fa fa-chevron-up'></i>
			</a>

			{/* <div id="preloader"></div> */}
		</div>
	);
}

export default HomePage;
