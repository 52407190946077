import './App.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';

import HomePage from './pages/home'

function App() {
  console.log("hi")
  return (
    <HomePage/>
  );
}

export default App;
